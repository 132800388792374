import { Box, Typography } from "@mui/material";

export function Footer() {
    return (
        <Box 
          sx={{ width: '100%', position: 'absolute' }}
        >
            <Typography>Hi</Typography>
            {/* <Container>
                <Toolbar disableGutters />
            </Container> */}
        </Box>
        
    )
}